import React, { useEffect, useState } from "react";
import ModalComponent from "common/components/Model";
import Grid from "@mui/material/Grid";
import MDBox from "common/components/MDBox";
import MDButton from "common/components/MDButton";
import "./style.css";
import { useDispatch, useSelector } from "react-redux";
import { getCourseDetails, registerCourse } from "services/student/student";
import Swal from "sweetalert2";
import DropBox from "common/components/DropDownBox/DropBox";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getIntakeList } from "services/intake/intake";
import { updateRegisteredCourse } from "services/student/student";

const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    timer: 2000,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        height: "3rem",
        backgroundColor: "#e6e6e6",
        position: "relative",
        borderRadius: "10px",
    },
    notchedOutline: {
        border: 0,
    },
}));

const AddNextIntakeModel = ({ setShowModal, showModal, assignDetails, id, update, item }) => {

    const [assignLoader, setAssignLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const [loader, setLoader] = useState(false);
    const [value, setValue] = useState("");
    const [intakeList, setIntakeList] = useState([])
    const [selectIntake, setSelectIntake] = useState('')
    const [paymentType, setPaymentType] = useState(1);
    const [amount, setAmout] = useState('')
    const [isDisabled, setIsDisabled] = useState(true);
    const [installmentType, setInstallmentType] = useState("");
    const classes = useStyles();
    const dispatch = useDispatch();
    const registerCourses = useSelector((state) => state.registerCourseSlice.student);
    const registration = useSelector((state) => state.updateRegisteredCourseSlice.registration);
    const allIntake = useSelector(state => state.intakeSlice.intake);

    const feesArray = [
        {
            id: 1,
            name: "Standard",
            text: "standard",
        },
        {
            id: 2,
            name: "Scholarship",
            text: "scholarship",
        },
        !update && {
            id: 3,
            name: "Free",
            text: "free",
        },
    ];

    useEffect(() => {
        dispatch(getIntakeList());
    }, []);

    useEffect(() => {
        if (update) {
            setSelectIntake(item?.intakeId)
            setPaymentType(feesArray.filter(val => val.text == item?.type)[0].id)
        } else {
            setSelectIntake(assignDetails?.current_intake?.id)
        }
    }, [assignDetails])

    useEffect(() => {
        if (allIntake.isSuccess) {
            const array = [];
            allIntake.data.forEach((val) => {
                if (assignDetails?.id == val?.course_id) {
                    array.push(
                        {
                            id: val.id,
                            name: val.intake,
                            text: val.intake,
                            course_fee: val.course_fee
                        }
                    )
                }
            })
            setIntakeList(array)
        }
    }, [allIntake.data])

    const assignCourse = () => {
        const form = new FormData();
        form.append("student_id", parseInt(id));
        form.append("intake_id", selectIntake);
        form.append("course_id", assignDetails?.id);
        form.append("type", installmentType);
        form.append("amount", parseInt(amount.replace(/[, ]+/g, "").trim()));
        setAssignLoader(true);
        dispatch(registerCourse(form));
        setLoader(true);
    };

    const handelUpdate = () => {
        const form = new FormData();
        form.append("type", installmentType);
        form.append("amount", parseInt(amount.replace(/[, ]+/g, "").trim()));
        dispatch(updateRegisteredCourse({ id: item.uid, page: form }))
        setUpdateLoader(true);
    }

    useEffect(() => {
        if (updateLoader) {
            setUpdateLoader(false);
            if (registration.isSuccess && !registration.isLoading) {
                setShowModal(false);
                dispatch(getCourseDetails({ id: id }));
                Toast.fire({
                    icon: "success",
                    title: "Intake Updated Succesfully",
                });
            } else if (registration.isError) {
                Toast.fire({
                    icon: "error",
                    title: "Invalid Input",
                });
            }
        }
    }, [registration.data]);

    useEffect(() => {
        if (registerCourses.errorMessage === "Field already added" && loader) {
            setShowModal(false);
            Toast.fire({
                icon: "error",
                title: "Student already added to Current Intake",
            });
            setLoader(false);
        }
    }, [registerCourses.errorMessage, loader]);

    useEffect(() => {
        if (assignLoader) {
            setAssignLoader(false);
            if (registerCourses.isSuccess && !registerCourses.isLoading) {
                dispatch(getCourseDetails({ id: id }));
                Toast.fire({
                    icon: "success",
                    title: "New Intake Assign Successfully",
                });
                setShowModal(false);
            } else if (registerCourses.isError) {
                Toast.fire({
                    icon: "error",
                    title: "Invalid Input",
                });
            }
        }
    }, [registerCourses.data]);

    useEffect(() => {
        const selectValue = intakeList.filter(val => val.id == selectIntake)[0]
        if (update && feesArray.filter(val => val.text == item?.type)[0].id == paymentType) {
            const typeSelect = feesArray.filter(val => val.text == item?.type)[0]
            setAmout(item?.amount);
            setIsDisabled(true);
            setPaymentType(typeSelect?.id)
            setInstallmentType(typeSelect?.text)
        } else {
            switch (paymentType) {
                case 1: {
                    setAmout(selectValue?.course_fee);
                    setIsDisabled(true);
                    setInstallmentType(feesArray[0].text)
                    break;
                }
                case 2: {
                    setAmout(selectValue?.course_fee);
                    setIsDisabled(false)
                    setInstallmentType(feesArray[1].text)
                    break;
                }
                case 3: {
                    setAmout(0.00);
                    setIsDisabled(true);
                    setInstallmentType(feesArray[2].text)
                    break;
                }
            }
        }
    }, [paymentType, selectIntake, intakeList])

    const onBlurHandler = (e) => {
        let inputText = parseFloat(e.target.value.replace(/,/g, "")).toFixed(2);
        inputText = inputText.replace(/,/g, "");
        inputText = inputText.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        if (e.target.value === "") {
            setValue("");
        } else {
            setValue(inputText);
        }
    };

    const handleChange = (e) => {
        const inputValue = e.target.value.replace(/[^0-9]/g, "");
        const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        setAmout(formattedValue);
    };

    return (
        <ModalComponent setShowModal={setShowModal} showModal={showModal}>
            <MDBox p={3}>
                <h4 className="heading" pb={2}>
                    {update ? "Update Intake" : "Assign Next Intake"}
                </h4>
                <Grid container pt={2}>
                    <Grid item xs={12} sm={3} md={4} xl={4}></Grid>
                </Grid>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={3} md={4} xl={4}>
                        <div className="labelOne">
                            <p id="para1">Course Name:</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={9} md={8} xl={8}>
                        <div className="course-name-label">
                            <p id="para2">{assignDetails.title}</p>
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} xl={6}>
                        <DropBox
                            setListValue={setSelectIntake}
                            listValue={selectIntake}
                            dropArray={intakeList}
                            disabled={update}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} xl={12}>
                        <div
                            style={{
                                width: "100%",
                                backgroundColor: "#E6E6E6",
                                height: "1px",
                                marginTop: "10px",
                            }}
                        ></div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                        <DropBox
                            setListValue={setPaymentType}
                            listValue={paymentType}
                            dropArray={feesArray}
                            disabled={update && item?.completed_status == 'completed'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} xl={6}>
                        <TextField
                            id="para3"
                            className={classes.root}
                            value={amount}
                            onChange={handleChange}
                            onBlur={onBlurHandler}
                            disabled={isDisabled}
                            autoFocus
                            InputProps={{
                                classes: {
                                    notchedOutline: classes.notchedOutline,
                                },
                            }}
                        />
                    </Grid>
                    <Grid container mt={6}>
                        <Grid item xs={6} md={6} xl={6}></Grid>
                        <Grid item xs={12} md={6} xl={6}>
                            <Grid
                                container
                                gap={2}
                                pl={3}
                                sx={{
                                    display: "flex",
                                    flexDirection: { xs: "column", sm: "row" },
                                    flexWrap: "nowrap",
                                }}
                            >
                                <Grid item xs={12} md={6} xl={6}>
                                    <MDButton
                                        variant="outlined"
                                        color="secondary"
                                        fullWidth
                                        onClick={() => setShowModal(false)}
                                    >
                                        Cancel
                                    </MDButton>
                                </Grid>
                                <Grid item xs={12} md={6} xl={6}>
                                    {update ? (<MDButton
                                        variant="gradient"
                                        color='info'
                                        fullWidth
                                        disabled={update && item?.completed_status == 'completed'}
                                        onClick={handelUpdate}
                                    >
                                        Update
                                    </MDButton>)
                                        :
                                        (<MDButton
                                            variant="gradient"
                                            color='info'
                                            fullWidth
                                            disabled={registerCourses.isLoading}
                                            onClick={assignCourse}
                                        >
                                            Assign
                                        </MDButton>)
                                    }
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </ModalComponent>
    );
};
export default AddNextIntakeModel;

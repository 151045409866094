import Grid from "@mui/material/Grid";
import React, {useEffect, useState} from "react";
import './style.css'
import {Card, CardContent, Chip, Icon} from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDButton from "../MDButton";
import {useDispatch} from "react-redux";
import {completeStatus} from "services/student/student";
import {transaction} from "config/images";
import {useNavigate} from "react-router-dom";
import {certificateLogo} from "config/images";
import Tooltip from "@mui/material/Tooltip";

export default function CertificateCard(props) {
    const {intake, date, setPopup, debuttons, item, setUid,setUpdatePop , setItem , setDetails} = props;
    const [selectedStatus, setSelectedStatus] = useState('not_completed');
    const [courseStatus, setCourseStatus] = useState(null);
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setCourseStatus(event.currentTarget);
    };

    const handleClose = () => {
        setCourseStatus(null);
    };

    const capitalizeEachWord = (str) => {
        return str.replace(/\b\w/g, (match) => match.toUpperCase());
    }
    const handleStatusSelect = (status) => {
        setSelectedStatus(status);
        handleClose()
        dispatch(completeStatus({id: item.uid, completed_status: (status.toLowerCase())}))
    }

    useEffect(() => {
        setSelectedStatus(item.completed_status)
    }, [item])

    const navigate = useNavigate();
    return (
        <Card variant="outlined">
            <CardContent>
                <Grid container spacing={1} sx={{pt: 1}}>
                    <Grid item xs={12} sm={3.5} md={4} lg={2} xl={2} className="certificate-detail-intake"
                          sx={{display: "flex", alignItems: "center"}}>

                        {intake}

                    </Grid>
                    <Grid item xs={12} sm={3.5} md={3} lg={2} xl={2} className="certificate-detail-date"
                          sx={{display: "flex", alignItems: "center"}}>
                        {/* Item 2  */}
                        {date}
                    </Grid>

                    <Grid item xs={12} sm={5} md={5} lg={4} xl={4}>
                        {/* Item 3 */}
                        <Chip
                            label={capitalizeEachWord(selectedStatus)}
                            icon={<Icon fontSize="small">control_point</Icon>}
                            onClick={handleClick}
                            sx={{
                                background: selectedStatus === "not_completed" ? '#C9F1E8' : (selectedStatus === "completed" ? '#FFEED6' : '#F0E7FE'),
                                color: selectedStatus === "not_completed" ? '#FF4141' : (selectedStatus === "completed" ? '#AB6B2F' : '#6854E7'),
                            }}
                        />
                        <Menu
                            anchorEl={courseStatus}
                            anchorReference={null}
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                            }}
                            open={Boolean(courseStatus)}
                            onClose={handleClose}
                        >
                            <MenuItem onClick={() => handleStatusSelect("completed")}>
                                Completed
                            </MenuItem>
                            <MenuItem onClick={() => handleStatusSelect("not_completed")}>
                                Not Completed
                            </MenuItem>
                            <MenuItem onClick={() => handleStatusSelect("pause")}>
                                Pause
                            </MenuItem>
                        </Menu>

                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={4}>
                        <Grid container gap={2}>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2} sx={{display: "flex", alignItems: "center"}}>
                        {/* Item 4 */}
                        <Tooltip title="Update">
                            <MDButton className="certificate-button"
                                      variant="gradient"
                                      iconOnly={true}
                                      color={"clrBtn"}
                                      onClick={() => {
                                          setDetails()
                                          setItem(item)
                                          setUpdatePop(true)
                                      }}
                            >
                                <Icon fontSize="medium" >edit</Icon>
                            </MDButton>
                        </Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2} sx={{display: "flex", alignItems: "center"}}>
                        {/* Item 4 */}
                        <Tooltip title="Certificate">
                            <span>
                                <MDButton className="certificate-button"
                                          variant="gradient"
                                          iconOnly={true}
                                          onClick={() => {
                                              setPopup(true)
                                              setUid(item.registerId)
                                          }}
                                          disabled={selectedStatus !== "completed"}>
                                    <img src={certificateLogo} alt={certificateLogo}/>
                                </MDButton>
                            </span>
                        </Tooltip>
                    </Grid>

                    <Grid item xs={12} sm={2} md={2} lg={2} xl={2} sx={{display: "flex", alignItems: "center"}}>
                        {/* Item 5 */}
                        <Tooltip title="Payment">
                            <MDButton className="certificate-button"
                                      variant="gradient"
                                      iconOnly={true}
                                      onClick={() => {
                                          navigate(`/payment/${item.uid}`);
                                      }}
                            >
                                <img src={transaction} alt={transaction}/>
                            </MDButton></Tooltip>
                    </Grid>
                    <Grid item xs={12} sm={3} md={3} lg={3} xl={3} sx={{display: "flex", alignItems: "center"}}>
                        {/* Item 6 */}
                        {debuttons}
                    </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    );
}

import React from "react";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import styled from "@emotion/styled";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  icon: {
    display: "inline-block",
    width: "2rem",
    height: "auto",
    top: "50%",
    transform: "translateY(-50%)",
  },
});

export default function DropBox({ setListValue, listValue, dropArray, disabled=false }) {
  const StyledSelect = styled(Select)`
    width: 100%;
    height: 3rem;
    background-color: #e6e6e6;
    position: relative;
    border-radius: 10px;
    .MuiOutlinedInput-notchedOutline {
      border: 0;
    }
  `;

  const classes = useStyles();

  const handleChange = (event) => {
    setListValue(event.target.value);
  };

  const menuStyles = {
    color: "#726AA5",
  };

  return (
    <div>
      <Box sx={{ minWidth: 120 }}>
        <FormControl fullWidth>
          <StyledSelect
            value={listValue}
            onChange={handleChange}
            classes={{ icon: classes.icon }}
            disabled={disabled}
          >
            {dropArray.map((item) => (
              <MenuItem style={menuStyles} key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </StyledSelect>
        </FormControl>
      </Box>
    </div>
  );
}

import Grid from "@mui/material/Grid";
import MDBox from "common/components/MDBox";
import MDButton from "common/components/MDButton";
import Icon from "@mui/material/Icon";
import TableComponent from "common/components/table";
import React, {useEffect, useState} from "react";
import Switch from "@mui/material/Switch";
import MobileList from "common/components/mobileList/MobileList";
import "./style.css"
import AddCourseModel from "component/AddCourseModel";
import Swal from "sweetalert2";
import {deleteCourse, getCourseList} from "services/course/course";
import {useDispatch, useSelector} from "react-redux";

const Toast = Swal.mixin({
    toast: true, position: 'top',zIndex:10, showConfirmButton: false, timer: 2000, onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

const columns = [
    {field: 'title', headerName: 'Title', accessor: "title"},
    {field: 'content', headerName: 'Content', accessor: "content"},
    {field: 'state', headerName: 'State', accessor: "state"},
    {field: 'action', headerName: 'Action', accessor: "action"}
];

function Course() {

    const dispatch = useDispatch()
    const [popUp, setPop] = useState(false);
    const [updateCourse, setUpdateCourse] = useState(false)
    const [selectedCourse, setSelectedCourse] = useState({})
    const [tableData, setTableData] = useState([])
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const course = useSelector(state => state.courseSlice.course);
    const [deleteLoader, setDeleteLoader] = useState(false);
    const CourseDelete = useSelector(state => state.deleteCourseSlice.course);

    function getWindowDimensions() {
        const {
            innerWidth: width, innerHeight: height
        } = window;
        return {
            width, height
        };
    }

    const handleDelete = (id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: 'Do you want to delete this Course?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes'
        }).then((result) => {
            if (result.isConfirmed) {
                setDeleteLoader(true)
                dispatch(deleteCourse(id));
            }
        });
    };

    const updateCourses = (data) => {
        setUpdateCourse(true);
        setSelectedCourse(data);
    }

    useEffect(() => {
        dispatch(getCourseList());
    }, []);

    useEffect(() => {
        if (deleteLoader) {
            setDeleteLoader(false)
            if (CourseDelete.isSuccess) {
                dispatch(getCourseList())
                Toast.fire({
                    icon: 'success', title: 'Course Deleted Successfully!'
                });
            } else if (CourseDelete.isError) {
                Toast.fire({
                    icon: 'error', title: 'Course Failed to Delete '
                });
            }
        }
    }, [CourseDelete.data])

    useEffect(() => {
        if (course.isSuccess) {
            const data = course.data?.data;
            const array = [];
            data.forEach((val) => {
                array.push({
                    id:val.id,
                    title: val.title,
                    content: val.course_content,
                    state: <Switch checked={val.status === "active"}/>,
                    action: (<div className="my-class1">
                        <MDButton
                            variant="gradient"
                            color="success"
                            onClick={() => updateCourses(val)}
                            sx={{m:2}}
                        >
                            <Icon fontSize="medium">edit</Icon>
                            {windowDimensions.width >= 400 && windowDimensions.width <= 768 && "Edit"}
                        </MDButton>
                        <MDButton sx={{m:2}} variant="gradient" color="error" onClick={() => handleDelete(val.id)}

                        >
                            <Icon fontSize="medium">delete</Icon>
                            {windowDimensions.width >= 400 && windowDimensions.width <= 768 && "Delete"}
                        </MDButton>
                    </div>),
                })
            })
            setTableData(array)
        }
    }, [course.data])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [windowDimensions]);

    return (<MDBox pt={6} pb={3}>
        <Grid container spacing={6} justifyContent="flex-end">
            <Grid item>
                <MDButton variant="gradient"
                          color="info"
                          onClick={() => {
                              setPop(true)
                          }}>
                          <div className="Course_button-icon">
                    <Icon fontSize="large" sx={{marginRight:1,marginBottom:0.2}}>
                    control_point
                    </Icon>
                    &nbsp;Add New Course
                    </div>
                </MDButton>

            </Grid>
        </Grid>
        <Grid pt={6}>
            {windowDimensions.width > 768 ?
                <TableComponent data={tableData} columns={columns}/>
                :
                <MobileList COLUMNS={columns} dataJson={tableData} searchKey={['title', 'intake']}/>
            }
        </Grid>
        {popUp &&
            <AddCourseModel setShowModal={setPop} showModal={popUp}/>
        }
        {updateCourse &&
            <AddCourseModel setShowModal={setUpdateCourse} showModal={updateCourse} assignData={selectedCourse}
                            updateData={true}/>
        }
    </MDBox>);
}
export default Course;

import { createAsyncThunk } from "@reduxjs/toolkit";
import instance from "../AxiosOrders";

export const getMessages = createAsyncThunk('messages', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.get(`/contact`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const changeStates = createAsyncThunk('messages/change', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.post(`/contact/change_status`, page, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const deleteMessages = createAsyncThunk('messages/delete', async (page, { rejectWithValue }) => {
    try {
        const { data } = await instance.delete(`/contact/delete_contact/`+page);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});
import React, { useEffect, useState } from "react";
import ModalComponent from "common/components/Model";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MDButton from "common/components/MDButton";
import "./style.css";
import Switch from "@mui/material/Switch";
import { useDispatch, useSelector } from "react-redux";
import { getIntakeList, postIntake, updateIntake } from "services/intake/intake";
import Swal from "sweetalert2";
import CommonSelect from "common/components/CustomSelect";

const Toast = Swal.mixin({
  toast: true,
  position: 'top',
  zIndex: 10,
  showConfirmButton: false,
  timer: 2000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const AddIntakeModel = ({ setShowModal, showModal, updateData, assignData }) => {

  const dispatch = useDispatch();
  const intakes = useSelector((state) => state.saveIntakeSlice.intake);
  const intakeUpdate = useSelector((state) => state.updateIntakeSlice.intake);
  const course = useSelector(state => state.courseSlice.course);

  const [intake, setIntake] = useState(updateData ? assignData.intake : "");
  const [courseFee, setCourseFee] = useState(updateData ? assignData.course_fee : "");
  const [discount, setDiscount] = useState(updateData ? assignData.discount : "");
  const [date, setDate] = useState(updateData ? assignData.started_date : "");
  const [selectCourse, setSelectCourse] = useState(updateData ? assignData.course_id : '');
  const [courseOption, setCourseOption] = useState([]);
  const [registrationAllowed, setRegistrationAllowed] = useState(
    updateData ? assignData.reg_fee : 0
  );
  const [saveLoader, setSaveLoader] = useState(false);
  const [updateLoader, setUpdateLoader] = useState(false);

  const saveIntake = () => {
    const form = new FormData();
    form.append("intake", intake);
    form.append("course_fee", parseInt(courseFee));
    form.append("course_id", selectCourse);
    form.append("discount", discount);
    form.append("started_date", date);
    form.append("reg_fee", registrationAllowed);
    setSaveLoader(true);
    dispatch(postIntake(form));
  };

  const handleUpdateIntake = () => {
    const form = new FormData();
    form.append("intake", intake);
    form.append("started_date", date);
    form.append("course_fee", parseInt(courseFee));
    form.append("discount", discount);
    form.append("reg_fee", registrationAllowed);
    setUpdateLoader(true);
    dispatch(updateIntake({ id: assignData.id, intakeUpdate: form }));
  };

  useEffect(() => {
    if (course.isSuccess) {
      const arr = []
      course.data?.data.forEach(val => {
        arr.push({ label: val.title, value: val.id },)
      });
      setCourseOption(arr)
    }
  }, [course.data])

  const handleRegistrationChange = (e) => {
    const newStatus = e.target.checked ? 1 : 0
    setRegistrationAllowed(newStatus);
  };

  useEffect(() => {
    if (saveLoader) {
      setSaveLoader(false);
      if (intakes.isSuccess && !intakes.isLoading) {
        setShowModal(false);
        dispatch(getIntakeList());
        Toast.fire({
          icon: "success",
          title: "Intake Added Successfully",
        });
      } else if (intakes.isError) {
        Toast.fire({
          icon: "error",
          title: "Invalid Input",
        });
      }
    }
  }, [intakes.data]);

  useEffect(() => {
    if (updateLoader) {
      setUpdateLoader(false);
      if (intakeUpdate.isSuccess && !intakeUpdate.isLoading) {
        setShowModal(false);
        dispatch(getIntakeList());
        Toast.fire({
          icon: "success",
          title: "Intake Updated Successfully",
        });
      } else if (intakeUpdate.isError) {
        Toast.fire({
          icon: "error",
          title: "Invalid Input",
        });
      }
    }
  }, [intakeUpdate.data]);

  const clearText = () => {
    setIntake("");
    setDate("");
    setSelectCourse('')
    setCourseFee("");
    setDiscount("");
    setRegistrationAllowed(0);
  };

  const onBlurHandler = (e) => {
    let inputText = parseFloat(e.target.value.replace(/,/g, "")).toFixed(2);
    inputText = inputText.replace(/,/g, "");
    if (e.target.value === "") {
      setCourseFee("");
    } else {
      setCourseFee(inputText);
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    setCourseFee(inputValue);
  };

  const handleChangeCourse = (event) => {
    setSelectCourse(event.target.value);
  };

  return (
    <ModalComponent
      setShowModal={setShowModal}
      showModal={showModal}
      maxWidth={"sm"}
    >
      <Grid container p={3} spacing={3}>
        <Grid item xs={12} sm={12} lg={12} md={12} xl={12}>
          <TextField
            id="outlined-helperText"
            label="Title"
            value={intake}
            onChange={(e) => setIntake(e.target.value)}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
          <TextField
            id="courseFee"
            label="Course Fee"
            placeholder="0.00"
            value={courseFee}
            onChange={handleChange}
            onBlur={onBlurHandler}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} lg={6} md={6} xl={6}>
          <TextField
            id="discount"
            label="Discount"
            value={discount}
            onChange={(e) => setDiscount(e.target.value.replace(/[^0-9]/g, ""))}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <TextField
            id="date"
            label="Date"
            type="date"
            value={date}
            onChange={(e) => setDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ width: '100%' }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <CommonSelect
            id="outlined-helperText"
            options={courseOption}
            value={selectCourse}
            onChange={handleChangeCourse}
            label="Select Course"
            disabled={updateData}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={12} xl={12}>
          Registration Fee Allowed
          <Switch
            color="secondary"
            checked={registrationAllowed === 1}
            onChange={handleRegistrationChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          {updateData ? (
            ""
          ) : (
            <MDButton
              variant="outlined"
              color="secondary"
              disabled={updateData}
              sx={{ width: '100%' }}
              onClick={() => { clearText() }}
            >
              Clear
            </MDButton>
          )}

        </Grid>
        <Grid item xs={12} sm={6} md={6} xl={6}>
          <MDButton
            variant="gradient"
            color={updateData ? "success" : "info"}
            disabled={intake === "" || date === "" || intakes.isLoading || intakeUpdate.isLoading || selectCourse === ''}
            sx={{ width: '100%' }}
            onClick={() => { updateData ? handleUpdateIntake() : saveIntake() }}
          >
            {updateData ? "Update" : "Add Intake"}
          </MDButton>
        </Grid>
      </Grid>
    </ModalComponent>
  );
};

export default AddIntakeModel;

import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import TableComponent from "common/components/table";
import MobileList from "common/components/mobileList/MobileList";
import MDBox from "common/components/MDBox";
import MDButton from "common/components/MDButton";
import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { getMessages, changeStates, deleteMessages } from "services/messages/messages";
import Checkbox from '@mui/material/Checkbox';

const columns = [
    { field: 'name', headerName: 'Name', accessor: "name" },
    { field: 'email', headerName: 'Email', accessor: "email" },
    { field: 'phone', headerName: 'Phone', accessor: "phone" },
    { field: 'message', headerName: 'Message', accessor: "message" },
    { field: 'status', headerName: 'Status', accessor: "status" },
    { field: 'action', headerName: 'Action', accessor: "action" }
];

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

const Toast = Swal.mixin({
    toast: true,
    position: 'top',
    showConfirmButton: false,
    timer: 2000,
    onOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer);
        toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

function Acpt() {

    const dispatch = useDispatch();
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [tableData, setTableData] = useState([]);
    const msg = useSelector(state => state.allMessagesSlice.msg);
    const updateMsg = useSelector(state => state.updateMessagesSlice.msg);
    const deleteMsg = useSelector(state => state.deleteMessagesSlice.msg);

    useEffect(() => {
        dispatch(getMessages())
    }, []);

    const changeStatusMsg = (status, id) => {
        const obj = {
            "id": id,
            "read_status": status,
        }
        dispatch(changeStates(obj))
    }

    useEffect(() => {
        if (msg.isSuccess && !msg.isLoading) {
            const array = [];
            msg.data?.forEach((val, index) => {
                array.push({
                    name: val.name,
                    email: val.email,
                    phone: val.number,
                    message: val.message,
                    status: (
                        <div>
                            <Checkbox onChange={(e) => changeStatusMsg(e.target.checked, val.id)} {...label} checked={val.read_status != 0} />
                        </div>
                    ),
                    action: (
                        <MDButton variant="gradient" color="error" onClick={() => deleteRow(val.id)}>
                            <Icon fontSize="medium">delete</Icon>
                            {windowDimensions.width >= 400 && windowDimensions.width <= 768 && "Delete"}
                        </MDButton>
                    ),
                })
            })
            setTableData(array);
        }
    }, [msg.data])

    useEffect(() => {
        if (updateMsg.isSuccess && !updateMsg.isLoading) {
            Toast.fire({
                icon: 'success',
                title: 'Change Status!'
            });
            dispatch(getMessages())
        }
    }, [updateMsg.data, updateMsg])

    function deleteRow(id) {
        Swal.fire({
            title: 'Are you sure?',
            text: "You will be able to delete this!",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            if (result.isConfirmed) {
                dispatch(deleteMessages(id))
            }
        })
    }

    useEffect(() => {
        if (deleteMsg.isSuccess && !deleteMsg.isLoading) {
            dispatch(getMessages())
            Toast.fire({
                icon: 'success',
                title: 'Deleted Successfully!'
            });
        }
    }, [deleteMsg.data, deleteMsg])

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [windowDimensions]);

    function getWindowDimensions() {
        const {
            innerWidth: width,
            innerHeight: height
        } = window;
        return {
            width,
            height
        };
    }

    return (
        <MDBox pt={6} pb={3} >
            <div className="content">
                <Grid pt={6}>
                    {windowDimensions.width > 768 ?
                        <TableComponent data={tableData} columns={columns} />
                        :
                        <MobileList COLUMNS={columns} dataJson={tableData} searchKey={['name', 'phone']} />
                    }
                </Grid>
            </div>
        </MDBox>
    )
}

export default Acpt;

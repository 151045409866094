import React, {useEffect, useState} from "react";
import ModalComponent from "common/components/Model";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import MDBox from "common/components/MDBox";
import CommonSelect from "common/components/CustomSelect";
import MDButton from "common/components/MDButton";
import "./style.css";
import ImageUploader from "common/components/ImageUploader";
import Switch from "@mui/material/Switch";
import {useDispatch, useSelector} from "react-redux";
import { getCourseList, postCourse, updateCourse } from "services/course/course";
import Swal from "sweetalert2";
import {onImageEdit, getImageUrl} from "common/utils/CommonFunctions";

const Toast = Swal.mixin({
    toast: true,
    position: "top",
    showConfirmButton: false,
    zIndex: 10,
    timer: 2000,
    onOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
    },
});

const AddCourseModel = ({ setShowModal, showModal, updateData, assignData }) => {

    const optionsCourse = [
        { label: "Online", value: "Online" },
        { label: "Physical", value: "Physical" },
        { label: "Online / Physical", value: "Online / Physical" },
    ];
    const [imageUrl, setImageUrl] = useState(
        updateData
            ? getImageUrl(assignData.image)
            : ""
    );
    const [postImage, setPostImage] = useState();
    const [content, setContent] = useState(
        updateData ? assignData.course_content : ""
    );
    const [title, setTitle] = useState(updateData ? assignData.title : "");
    const [medium, setMedium] = useState(updateData ? assignData.medium : "");
    const [state, setState] = useState(updateData ? assignData.status : "");
    const [saveLoader, setSaveLoader] = useState(false);
    const [updateLoader, setUpdateLoader] = useState(false);
    const dispatch = useDispatch();
    const course = useSelector((state) => state.saveCourseSlice.course);
    const CourseUpdate = useSelector((state) => state.UpdateCourseSlice.course);
    const [isValidImage, setIsValidImage] = useState(true);

    const handlePostImage = (val) => {
        const img = new Image();
        img.onload = function () {
            if (img.width === 3000 && img.height === 1000) {
                setIsValidImage(true);
                setPostImage(val);
            } else {
                setIsValidImage(false);
            }
        };
        img.src = URL.createObjectURL(val);
    };

    const handleChangeMedium = (event) => {
        setMedium(event.target.value);
    };

    const saveCourse = () => {
        const form = new FormData();
        form.append("image", postImage);
        form.append("title", title);
        form.append("course_content", content);
        form.append("medium", medium);
        form.append("status", "active");

        setSaveLoader(true);
        dispatch(postCourse(form));
    };

    const handleUpdateCourse = () => {
        const form = new FormData();
        form.append("image", postImage);
        form.append("title", title);
        form.append("course_content", content);
        form.append("medium", medium);
        form.append("status", state);

        setUpdateLoader(true);
        dispatch(updateCourse({id: assignData.id, updatedCourse: form}));
    };
    const handleStatusChange = (e) => {
        const newState = e.target.checked ? "active" : "inactive";
        setState(newState);
    };
    useEffect(() => {
        if (saveLoader) {
            setSaveLoader(false);
            if (course.isSuccess && !course.isLoading) {
                setShowModal(false);
                dispatch(getCourseList());
                Toast.fire({
                    icon: "success",
                    title: "Course Added Successfully",
                });
            } else if (course.isError) {
                Toast.fire({
                    icon: "error",
                    title: "Invalid Input",
                });
            }
        }
    }, [course.data]);

    useEffect(() => {
        if (updateData) {
            onImageEdit(
                getImageUrl(assignData.image)
            ).then((file) => setPostImage(file));
        }
    }, []);

    useEffect(() => {
        if (updateLoader) {
            setUpdateLoader(false);
            if (CourseUpdate.isSuccess && !CourseUpdate.isLoading) {
                setShowModal(false);
                dispatch(getCourseList());
                Toast.fire({
                    icon: "success",
                    title: "Course Update Successfully",
                });
            } else if (CourseUpdate.isError) {
                Toast.fire({
                    icon: "error",
                    title: "Invalid Input",
                });
            }
        }
    }, [CourseUpdate.data]);

    const clearText = () => {
        setTitle("");
        setContent("");
        setPostImage("");
        setState("");
    };

    return (
        <ModalComponent
            setShowModal={setShowModal}
            showModal={showModal}
            maxWidth={"sm"}
        >
            <MDBox
                pb={3}
                sx={{
                    padding: {
                        xs: 2,
                        md: 4,
                    },
                    paddingTop: {
                        xs: 6,
                    },
                    mt: 2,
                }}
            >
                <Grid container spacing={2}>
                    {/* First row */}
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <TextField
                            id="outlined-helperText"
                            label="Title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            sx={{
                                width: {
                                    xs: "100%",
                                    sm: "100%",
                                    md: "100%",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12}>
                        <CommonSelect
                            id="outlined-helperText"
                            options={optionsCourse}
                            value={medium}
                            onChange={handleChangeMedium}
                            label="Medium"
                        />
                    </Grid>
                    {/* Second row*/}
                    <Grid item xs={12} sm={6} md={5} lg={7}>
                        <TextField
                            id="outlined-multiline-static"
                            label="Content"
                            multiline
                            rows={6}
                            value={content}
                            onChange={(e) => setContent(e.target.value)}
                            sx={{
                                width: {
                                    xs: "100%",
                                    md: "100%",
                                },
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={5}>
                        <ImageUploader
                            postImage={handlePostImage}
                            imageUrl={imageUrl}
                            onImageSelect={setImageUrl}
                            width="150px"
                            height="150px"
                        />{" "}
                        {!isValidImage && (
                            <div className="image-upload">You are added invalid image</div>
                        )}
                        {updateData && (
                            <Grid>
                                State
                                <Switch
                                    name="state"
                                    checked={state === "active"}
                                    onChange={handleStatusChange}
                                />
                            </Grid>
                        )}
                    </Grid>

                    {/* Third row */}
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6} md={6} xl={6}>
                                {updateData ? (
                                    ""
                                ) : (
                                    <MDButton
                                        variant="outlined"
                                        color="secondary"
                                        disabled={updateData}
                                        sx={{
                                            width: {
                                                xs: "100%",
                                                md: "100%",
                                            },
                                        }}
                                        onClick={() => {
                                            clearText();
                                        }}
                                    >
                                        Clear
                                    </MDButton>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={6} md={6} xl={6}>
                                <MDButton
                                    variant="gradient"
                                    color={updateData ? "success" : "info"}
                                    disabled={
                                        title === "" ||
                                        content === "" ||
                                        !imageUrl ||
                                        !isValidImage ||
                                        course.isLoading || CourseUpdate.isLoading
                                    }
                                    sx={{
                                        width: {
                                            xs: "100%",
                                            md: "106%",
                                        },
                                    }}
                                    onClick={
                                        updateData
                                            ? () => {
                                                handleUpdateCourse();
                                            }
                                            : () => {
                                                saveCourse();
                                            }
                                    }
                                >
                                    {updateData ? "Update" : "Add Course"}
                                </MDButton>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MDBox>
        </ModalComponent>
    );
};
export default AddCourseModel;

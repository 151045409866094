import Grid from "@mui/material/Grid";
import MDButton from "common/components/MDButton";
import MDBox from "common/components/MDBox";
import React, { useEffect, useState } from "react";
import CourseCard from "common/components/CourseCard";
import profile from "assets/images/profile 1.png";
import CertificateCard from "common/components/CertificateCard";
import "./style.css";
import Divider from "@mui/material/Divider";
import AddStudentModel from "component/AddStudentModel";
import AssignCourseModel from "component/AssignCourseModel";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteRegistration,
  getCourseDetails,
  getStudentDetails,
} from "services/student/student";
import { useParams } from "react-router-dom";
import { Icon } from "@mui/material";
import GenerateCertificateModel from "component/GenerateCertificateModel";
import Swal from "sweetalert2";
import AddNextIntakeModel from "component/AddNextIntakeModel";
import { getImageUrl } from "common/utils/CommonFunctions";
import CopyText from "common/components/CopyText/CopyText";

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  zIndex: 10,
  showConfirmButton: false,
  timer: 2000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

function StudentDetails() {
  const studentDetails = useSelector(
    (state) => state.getStudentDetailsSlice.student
  );
  const courseDetails = useSelector(
    (state) => state.getCourseDetailsSlice.student
  );
  const complete = useSelector((state) => state.completeStatusSlice.status);
  const deleteRegistrations = useSelector(
    (state) => state.deleteRegistrationSlice.registration
  );
  const [updateStudent, setUpdateStudent] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState({});
  const [popUp, setPopUp] = useState(false);
  const [nextIntakePop, setNextIntakePop] = useState(false);
  const [pop, setPop] = useState(false);
  const [updatePop, setUpdatePop] = useState(false);
  const dispatch = useDispatch();
  const [sId, setSId] = useState("");
  const [name, setName] = useState("");
  const [nic, setNic] = useState("");
  const [item, setItem] = useState({});
  const [email, setEmail] = useState("");
  const [number, setNumber] = useState("");
  const [status, setStatus] = useState("");
  const [manageData, setManageData] = useState([]);
  const [manageIntakeData, setManageIntakeData] = useState([]);
  const { id } = useParams();
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [selectedComponentIndex, setSelectedComponentIndex] = useState(null);
  const [selectedIntake, setSelectedIntake] = useState(null);
  const [uid, setUid] = useState(null);
  const [listValue, setListValue] = useState(1);
  const [copiedValue, setCopiedValue] = useState("");

  const handleDelete = (uid) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete this intake?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.isConfirmed) {
        setDeleteLoader(true);
        dispatch(deleteRegistration(uid));
      }
    });
  };

  useEffect(() => {
    if (!complete.isLoading && complete.isSuccess) {
      dispatch(getCourseDetails({ id: id }));
    }
  }, [complete.data])

  const updateStudentDetails = (data) => {
    setUpdateStudent(true);
    setSelectedStudent(data);
  };

  useEffect(() => {
    dispatch(getCourseDetails({ id: id }));
  }, []);

  useEffect(() => {
    if (deleteLoader) {
      setDeleteLoader(false);
      if (deleteRegistrations.isSuccess) {
        dispatch(getCourseDetails({ id: id }));
        Toast.fire({
          icon: "success",
          title: "Intake deleted successfully!",
        });

        setSelectedComponentIndex(0);
        setSelectedIntake(manageData[0]);
      } else if (deleteRegistrations.isError) {
        Toast.fire({
          icon: "error",
          title: "Failed to delete intake",
        });
      }
    }
  }, [deleteRegistrations.data]);

  useEffect(() => {
    if (courseDetails.isSuccess) {
      let array = [];
      let intakeArray = [];
      const data = courseDetails?.data?.data; 
      data.forEach((item) => {
        array.push({
          id: item?.course?.id,
          title: item?.course?.title,
          image: item?.course?.image,
          current_intake: item?.course?.active_intake,
          intake: item?.intake?.intake,
          started_date: item?.intake?.started_date,
          completed_status: item.completed_status,
        });
        intakeArray.push({
          uid: item?.id,
          registerId: item?.uid,
          intake: item?.intake?.intake,
          started_date: item?.intake?.started_date,
          id: item?.course?.id,
          completed_status: item.completed_status,
          intakeId: item?.intake?.id,
          amount: item?.amount,
          type: item?.type
        });
      });

      const removeDuplicate = array.filter(
        (item, index, arr) => index === arr.findIndex((t) => t.id === item.id)
      );
      setManageData(removeDuplicate);
      setManageIntakeData(intakeArray);
    }
  }, [courseDetails]);

  useEffect(() => {
    if (studentDetails.isSuccess) {
      const data = studentDetails.data?.data[0];
      setSId(data.custom_id);
      setName(data.name);
      setNic(data.nic);
      setEmail(data.email);
      setNumber(data.contact_no);
      setStatus(data.status);
    }
  }, [studentDetails]);

  useEffect(() => {
    dispatch(getStudentDetails({ id: id }));
  }, []);

  useEffect(() => {
    if (copiedValue !== "") {
      setTimeout(() => setCopiedValue(""), 3000);
    }
  }, [copiedValue]);

  const setDetails = () => {
    // dispatch(getCourseList());
  };
  return (
    <MDBox pb={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={4} xl={4}>
          <Grid className="detail-card">
            <Grid container pt={1}>
              <Grid container justifyContent="flex-end">
                <Grid pr={1}>
                  <MDButton
                    disabled
                    variant="outlined"
                    color={status === "active" ? "success" : "error"}
                    sx={{
                      borderRadius: "20px",
                      border:
                        status === "active"
                          ? "2px solid #12E500"
                          : "2px solid #e74c3c",
                      color: status === "active" ? "#12E500" : "#f50f0f",
                    }}
                  >
                    {status === "active" ? "Active" : "De-active"}
                  </MDButton>
                </Grid>
              </Grid>
              <Grid container justifyContent="center">
                <img src={profile} alt="" width="150px" />
              </Grid>
              <Grid container justifyContent="center">
                <Grid className="student-detail">{sId}</Grid>
              </Grid>
            </Grid>
            <Grid container pl={2} pt={4} gap={2}>
              <Grid container direction="row" className="row">
                <Grid item xs={4} className="basic-detail">
                  Name:
                </Grid>
                <Grid item xs={8} className="student-detail">
                  <CopyText
                    val={name}
                    copiedValue={copiedValue}
                    setCopiedValue={setCopiedValue}
                    top="32%"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" className="row">
                <Grid container xs={4} className="basic-detail">
                  NIC:
                </Grid>
                <Grid container xs={8} className="student-detail">
                  <CopyText
                    val={nic}
                    copiedValue={copiedValue}
                    setCopiedValue={setCopiedValue}
                    top="39%"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" className="row">
                <Grid container xs={4} className="basic-detail">
                  Email:
                </Grid>
                <Grid
                  container
                  xs={8}
                  className="student-detail"
                  sx={{ wordWrap: "break-word" }}
                >
                  <CopyText
                    val={email}
                    copiedValue={copiedValue}
                    setCopiedValue={setCopiedValue}
                    top="46.5%"
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" className="row">
                <Grid container xs={4} className="basic-detail">
                  Number:
                </Grid>
                <Grid container xs={8} className="student-detail">
                  <CopyText
                    val={number}
                    copiedValue={copiedValue}
                    setCopiedValue={setCopiedValue}
                    top="53.5%"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Divider className="divider" />
            <Grid
              container
              justifyContent="center"
              sx={{
                pt: {
                  xs: 4,
                  xl: 1,
                },
                pb: {
                  xs: 4,
                },
              }}
            >
              <MDButton
                variant="gradient"
                color="success"
                sx={{ width: "80%" }}
                onClick={() => {
                  updateStudentDetails();
                }}
              >
                Update
              </MDButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} lg={8} xl={8}>
          <Grid className="assign-card">
            <Grid container justifyContent="flex-end">
              <Grid pt={1} pr={3}>
                <MDButton
                  variant="gradient"
                  disabled={status !== "active"}
                  color="info"
                  onClick={() => {
                    setPopUp(true);
                  }}
                >
                  Assign Course
                </MDButton>
              </Grid>
            </Grid>
            <Grid className="assign-card-detail">
              {manageData.map((item, index) => (
                <CourseCard
                  key={index}
                  name={item.title}
                  image={getImageUrl(item.image)}
                  imageWidth={80}
                  index={index}
                  state={index === selectedComponentIndex}
                  selectedIntake={(val) => {
                    setSelectedComponentIndex(val);
                    setSelectedIntake(item);
                  }}
                  button={
                    <Icon
                      className="plus-icon"
                      fontSize="medium"
                      onClick={() => {
                        setNextIntakePop(true);
                        setDetails();
                      }}
                    >
                      add
                    </Icon>
                  }
                />
              ))}
            </Grid>
          </Grid>
          <Grid className="certificate-card">
            {selectedIntake &&
              manageIntakeData
                .filter((item) => item.id === selectedIntake.id)
                .map((item, index) => (
                  <CertificateCard
                    key={index}
                    intake={item.intake}
                    date={item.started_date}
                    debuttons={
                      <div>
                        <MDButton
                          variant="gradient"
                          color="error"
                          onClick={() => handleDelete(item.registerId)}
                        >
                          <Icon fontSize="medium">delete</Icon>
                        </MDButton>
                      </div>
                    }
                    setPopup={setPop}
                    setItem={setItem}
                    item={item}
                    setUid={setUid}
                    setUpdatePop={setUpdatePop}
                    setDetails={setDetails}
                  />
                ))}
          </Grid>
        </Grid>
      </Grid>

      {pop && (
        <GenerateCertificateModel
          setShowModal={setPop}
          showModal={pop}
          email={email}
          uid={uid}
        />
      )}
      {updateStudent && (
        <AddStudentModel
          setShowModal={setUpdateStudent}
          showModal={updateStudent}
          updateData={true}
          assignData={selectedStudent}
          id={id}
          sId={sId}
          sName={name}
          sNumber={number}
          sEmail={email}
          sNic={nic}
          sStatus={status}
        />
      )}
      {popUp && (
        <AssignCourseModel
          assignedCourse={manageData}
          setShowModal={setPopUp}
          showModal={popUp}
          id={id}
          assignData={selectedStudent}
          listValue={listValue}
          setListValue={setListValue}
        />
      )}
      {nextIntakePop && (
        <AddNextIntakeModel
          setShowModal={setNextIntakePop}
          showModal={nextIntakePop}
          assignDetails={selectedIntake}
          id={id}
        />
      )}
      {updatePop && (
        <AddNextIntakeModel
          setShowModal={setUpdatePop}
          showModal={updatePop}
          assignDetails={selectedIntake}
          id={id}
          listValue={listValue}
          setListValue={setListValue}
          update={updatePop}
          item={item}
        />
      )}
    </MDBox>
  );
}

export default StudentDetails;

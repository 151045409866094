import React, { useEffect, useState } from "react";
import "./style.css"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Intake from "pages/course-intake/component/Intake";
import Course from "pages/course-intake/component/Course";
import MDBox from "common/components/MDBox";

function CourseDetails() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [activeTab, setActiveTab] = useState(0);
    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }
        {
            window.addEventListener('resize', handleResize);
            return () => window.removeEventListener('resize', handleResize);
        }
    }, [windowDimensions]);

    function getWindowDimensions() {
        const {
            innerWidth: width,
            innerHeight: height
        } = window;
        return {
            width,
            height
        };
    }

    return (
        <MDBox pt={4} pb={3}>
            <div className="content">
                <Tabs selectedIndex={activeTab} onSelect={setActiveTab}>
                    <TabList>
                        <Tab>Course</Tab>
                        <Tab>Intake</Tab>
                    </TabList>
                    <TabPanel>
                        <Course setActiveTab={setActiveTab} />
                    </TabPanel>
                    <TabPanel>
                        <Intake setActiveTab={setActiveTab} />
                    </TabPanel>
                </Tabs>
            </div>
        </MDBox>
    )
}
export default CourseDetails;

import ModalComponent from "common/components/Model";
import MDBox from "common/components/MDBox";
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import CourseAssignCard from "common/components/CourseAssignCard";
import "./style.css";
import MDButton from "common/components/MDButton";
import { useDispatch, useSelector } from "react-redux";
import { getCourseList } from "services/course/course";
import { getCourseDetails, registerCourse } from "services/student/student";
import Swal from "sweetalert2";
import DropBox from "common/components/DropDownBox/DropBox";
import { TextField } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { getIntakeList } from "services/intake/intake";

const Toast = Swal.mixin({
  toast: true,
  position: "top",
  showConfirmButton: false,
  timer: 2000,
  onOpen: (toast) => {
    toast.addEventListener("mouseenter", Swal.stopTimer);
    toast.addEventListener("mouseleave", Swal.resumeTimer);
  },
});

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "3rem",
    backgroundColor: "#e6e6e6",
    position: "relative",
    borderRadius: "10px",
  },
  notchedOutline: {
    border: 0,
  },
}));

const AssignCourseModel = ({ setShowModal, showModal, assignedCourse, id }) => {

  const dispatch = useDispatch();
  const course = useSelector((state) => state.courseSlice.course);
  const registerCourses = useSelector(
    (state) => state.registerCourseSlice.student
  );
  const allIntake = useSelector(state => state.intakeSlice.intake);
  const [courses, setCourses] = useState([]);
  const [selectedComponentIndex, setSelectedComponentIndex] = useState(null);
  const [selectedCourse, setSelectedCourse] = useState(null);
  const [assignLoader, setAssignLoader] = useState(false);
  const [listValue, setListValue] = useState(1);
  const [value, setValue] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const classes = useStyles();
  const [installmentType, setInstallmentType] = useState("");

  const [intakeList, setIntakeList] = useState([]);
  const [selectIntake, setSelectIntake] = useState(null);

  const feesArray = [
    {
      id: 1,
      name: "Standard",
      text: "standard",
    },
    {
      id: 2,
      name: "Scholership",
      text: "scholership",
    },
    {
      id: 3,
      name: "Free",
      text: "free",
    },
  ];

  useEffect(() => {
    dispatch(getCourseList());
    dispatch(getIntakeList());
  }, []);

  useEffect(() => {
    if (allIntake.isSuccess) {
      const array = [];
      allIntake.data.forEach((val) => {
        if (selectedCourse?.id == val?.course_id) {
          array.push(
            {
              id: val.id,
              name: val.intake,
              text: val.intake,
              course_fee: val.course_fee,
              course_id: val?.course_id,
            }
          )
        }

      })
      setIntakeList(array);
      setSelectIntake(selectedCourse?.current_intake[0].id);
    }
  }, [allIntake.data, selectedCourse])

  const assignCourse = () => {
    const form = new FormData();
    form.append("student_id", parseInt(id));
    form.append("intake_id", selectIntake);
    form.append("course_id", selectedCourse.id);
    form.append("type", installmentType);
    form.append("amount", parseInt(value.replace(/[, ]+/g, "").trim()));
    setAssignLoader(true);
    dispatch(registerCourse(form));
  };

  useEffect(() => {
    if (assignLoader) {
      setAssignLoader(false);
      if (registerCourses.isSuccess && !registerCourses.isLoading) {
        setShowModal(false);
        dispatch(getCourseDetails({ id: id }));
        Toast.fire({
          icon: "success",
          title: "Course Assigned Successfully",
        });
      } else if (registerCourses.isError) {
        Toast.fire({
          icon: "error",
          title: "Invalid input",
        });
      }
    }
  }, [registerCourses.data]);

  useEffect(() => {
    if (course?.data?.data) {
      const filterdArr = [];
      const courseData = course?.data?.data;
      let filteredCourse = assignedCourse;
      const Array = courseData.filter(
        (obj1) => !filteredCourse.some((obj2) => obj1.id === obj2.id)
      );
      Array.forEach((value) => {
        filterdArr.push({
          id: value.id,
          current_intake: value.intake,
          name: value.title,
          state: false,
          assosr: value.title,
        });
      });
      setCourses(filterdArr);
    }
  }, [course.data, assignedCourse]);

  useEffect(() => {
    if (selectIntake) {
      const select = intakeList.filter(val => val.id == selectIntake)
      switch (listValue) {
        case 1: {
          setValue(select[0]?.course_fee);
          setIsDisabled(true);
          setInstallmentType(feesArray[0].text);
          break;
        }
        case 2: {
          setValue(select[0]?.course_fee);
          setIsDisabled(false);
          setInstallmentType(feesArray[1].text);
          break;
        }
        case 3: {
          setValue('0.0');
          setIsDisabled(true);
          setInstallmentType(feesArray[2].text);
          break;
        }
      }
    }
  }, [selectIntake, listValue])

  const onBlurHandler = (e) => {
    let inputText = parseFloat(e.target.value.replace(/,/g, "")).toFixed(2);
    inputText = inputText.replace(/,/g, "");
    inputText = inputText.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    if (e.target.value === "") {
      setValue("");
    } else {
      setValue(inputText);
    }
  };

  const handleChange = (e) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, "");
    const formattedValue = inputValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    setValue(formattedValue);
  };

  return (
    <ModalComponent
      setShowModal={setShowModal}
      showModal={showModal}
      maxWidth={"sm"}
    >
      <MDBox p={3}>
        <h4 className="heading">Assign New Course</h4>
        <Grid container spacing={3} justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            {courses.length > 0 ? (
              courses.map((course, index) => (
                <CourseAssignCard
                  key={index}
                  name={course.name}
                  state={index === selectedComponentIndex}
                  assor={course.assor}
                  index={index}
                  selectedCourse={(val) => {
                    setSelectedComponentIndex(val);
                    setSelectedCourse(course);
                  }}
                />
              ))
            ) : (
              <p className="unavailable-course">Unavailable course</p>
            )}
          </Grid>
          {(
            <Grid item xs={12} sm={6} xl={6}>
              <DropBox
                setListValue={setSelectIntake}
                listValue={selectIntake}
                dropArray={intakeList}
              />
            </Grid>
          )}

          <Grid item xs={12} sm={6} xl={6}>
            <div
              style={{
                width: "100%",
                backgroundColor: "#E6E6E6",
                height: "1px",
              }}
            ></div>
          </Grid>

          <Grid item xs={12} sm={6} md={6} xl={6}>
            <DropBox
              setListValue={setListValue}
              listValue={listValue}
              dropArray={feesArray}
              disabled={!selectIntake}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={6} xl={6}>
            <TextField
              id="para3"
              className={classes.root}
              value={value}
              onChange={handleChange}
              onBlur={onBlurHandler}
              disabled={isDisabled}
              autoFocus
              InputProps={{
                classes: {
                  notchedOutline: classes.notchedOutline,
                },
              }}
            />
          </Grid>
          <Grid item xs={12} xl={7}></Grid>
          <Grid item xs={12} xl={5} mt={2}>
            <MDButton
              variant="gradient"
              color="info"
              disabled={
                selectedCourse === null ||
                value === "" ||
                registerCourses.isLoading
              }
              sx={{ width: "100%" }}
              onClick={assignCourse}
            >
              Assign Course
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </ModalComponent>
  );
};

export default AssignCourseModel;

import axios from 'axios';
import { getItem } from "common/utils/Storage/Storage";

const token = getItem('login-token');

const instance = axios.create({
    //Dev server
    baseURL: 'https://main-api.acpt.lk/api',

    //Locat server
    // baseURL: "http://127.0.0.1:8000/api",

    // Live server
    //baseURL: 'https://api.acpt.lk/api',
    headers: { Authorization: `Bearer ${token}` },
});

export default instance;

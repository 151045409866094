import { createSlice } from "@reduxjs/toolkit";
import { getMessages } from "services/messages/messages";

const initialState = {
    msg : {
        data: [],
        isLoading: false,
        isSuccess: false,
        errorMessage: ''
    }
}

export const allMessagesSlice = createSlice({
    name: 'allMessages',
    initialState,
    extraReducers: {
        [getMessages.pending]: (state) => {
            state.msg.isLoading = true;
        },
        [getMessages.fulfilled]: (state, { payload }) => {
            state.msg.isLoading = false;
            state.msg.isSuccess = true;
            state.msg.data = payload;
        },
        [getMessages.rejected]: (state, { payload }) => {
            state.msg.isLoading = false;
            state.msg.isSuccess = false;
            state.msg.errorMessage = payload
        }
    }
})

export default allMessagesSlice.reducer;
import {createAsyncThunk} from "@reduxjs/toolkit";
import instance from "../AxiosOrders";


export const getStudentList = createAsyncThunk('student/getStudentList', async (page, {rejectWithValue}) => {
    try {
        const {data} = await instance.get(`/student`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getStudentCount = createAsyncThunk('student/getStudentCount', async (page, {rejectWithValue}) => {
    try {
        const {data} = await instance.get(`/dashboard/count`);
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const postStudent = createAsyncThunk('student/posStudent', async (page, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/student`, page, {
            headers: {"Content-type": "multipart/form-date"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
})

export const deleteStudent = createAsyncThunk('student/deleteStudent', async (id, {rejectWithValue}) => {
        try {
            const {data} = await instance.delete(`/student/${id}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);
export const getStudentDetails = createAsyncThunk('student/getStudentDetails', async ({id}, {rejectWithValue}) => {
    try {
        const {data} = await instance.get(`/student/by/${id}`, {
            headers: {"Content-type": "multipart/form-date"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const updateStudent = createAsyncThunk('student/updateStudent', async ({
                                                                                  id,
                                                                                  studentDetailsUpdate
                                                                              }, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/student/${id}`, studentDetailsUpdate, {
            headers: {"Content-type": "multipart/form-date"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const getCourseDetails = createAsyncThunk('student/getCourseDetails', async ({id}, {rejectWithValue}) => {
    try {
        const {data} = await instance.get(`/student/all/${id}`, {
            headers: {"Content-type": "multipart/form-date"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
});

export const registerCourse = createAsyncThunk('student/registerCourse', async (page, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/student/registerCourse`, page, {
            headers: {"Content-type": "multipart/form-date"},
        });
        return data;

    } catch (error) {
        return rejectWithValue(error?.response?.data);
    }
})

export const updateRegisteredCourse = createAsyncThunk('student/updateRegisteredCourse', async ({id, page}, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`registration/type_amount/${id}`, page, {
            headers: {"Content-type": "multipart/form-date"},
        });
        return data;

    } catch (error) {
        return rejectWithValue(error?.response?.data);
    }
})

export const deleteRegistration = createAsyncThunk('registration/deleteRegistration', async (uid, {rejectWithValue}) => {
        try {
            const {data} = await instance.delete(`/registration/${uid}`);
            return data;
        } catch (error) {
            return rejectWithValue(error.message);
        }
    }
);

export const completeStatus = createAsyncThunk('student/completeStatus', async (page, {rejectWithValue}) => {
    try {
        const {data} = await instance.post(`/complete_status`, page, {
            headers: {"Content-type": "application/json"},
        });
        return data;
    } catch (error) {
        return rejectWithValue(error.message);
    }
})

export const getCertificate = createAsyncThunk('/student/certificate', async (uid, {rejectedWithValue}) => {
    try {
        const {data} = await instance.get(`/student/${uid}`)
        return data;
    } catch (error) {
        return rejectedWithValue(error.message)
    }
})



//register details
export const getRegisterDetails = createAsyncThunk('register/getRegisterCount', async (page, {rejectWithValue}) => {
    try {
        const {data} = await instance.get(`/registration/all`);
        return data;
        
    } catch (error) {
        return rejectWithValue(error.message);
    }
});



//mailCertificate
export const postCertificate = createAsyncThunk( "mail/Certificate", async (uid, { rejectWithValue }) => {
    try {
      const { data } = await instance.post(`/certificate/mail/${uid}`, {
        headers: { "Content-type": "application/json" },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);